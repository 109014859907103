<template>
  <div class="container">
    <Header />
    <img class="banner" src="../../assets/contact-banner1.jpg" />
    <div class="content">
      <div>金天国际医疗科技集团有限公司</div>
      <div>电话：4001180929、0535-6300838</div>
      <div>地址：中国山东省烟台市福山区永达街986号</div>
    </div>
    <div class="map">
      <iframe :src=url scrolling="no" style="width: 100%;height: 200px;" frameborder="0"></iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      url:'/static/map.html'
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .banner {
    width: 100%;
    margin-top: 3.5rem;
  }
  .content {
    margin: 2rem 0 2rem 1rem;
    line-height: 2rem;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    color: #676767;
  }
  .map{
    padding:0.5rem;
  }
}
</style>